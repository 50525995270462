@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  .item {
    display: flex;
    width: 100%;
    .term {
      flex: 1;
    }
    .description {
      position: relative;
    }
  }
}
