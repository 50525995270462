@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .header {
    display: none;
    position: sticky;
    top: 0;
    height: 64px;
    z-index: 1;
  }
  .body {
    flex: 1;
    display: flex;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      height: auto;
    }
  }

  // authenticated
  &.authenticated {
    .header {
      display: block;
    }
    .pane-content {
      top: 64px;
    }
  }
}

.pane {
  flex-basis: 50%;
  display: flex;
  align-items: flex-start;

  &.pane-left {
    justify-content: flex-end;
    background-color: $brand;
  }

  &.pane-right {
    justify-content: flex-start;
    background-color: $white;

    @media (min-width: $breakpoint-md) {
      padding-top: 12px;
    }
  }

  @media (max-width: $breakpoint-md) {
    flex-basis: 100%;
    height: auto;

    &.pane-left,
    &.pane-right {
      justify-content: center;
    }
  }
}

.pane-content {
  position: sticky;
  top: 0;
  width: 400px;
  padding: 40px 24px;
  @media (max-width: $breakpoint-md) {
    padding: 24px;
    width: 100%;
  }
}
