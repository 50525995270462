@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  position: relative;
  .button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}

.dropdown {
  .label {
    padding: 8px 12px;
    font-size: 12px;
    font-family: Lato;
    color: $wolf-600;
  }
  .list {
    .item {
      padding: 8px 12px;
      font-size: 14px;
      font-family: Lato;
      white-space: nowrap;
      cursor: pointer;
      &:hover,
      &:focus {
        background: $wolf-100;
      }
    }
  }
}
