@import '@pushpress/shared-components/build/styles/config.scss';

.header {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $grid-unit-30;
  background-color: $white;
  border-bottom: 1px solid $border;
  background-color: $white;
  z-index: 2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), inset 0px -1px 0px #dae0e6;
  .name-container {
    flex: 1;
    display: flex;
    align-items: center;
    .user-name {
      @include body-lg;
      margin-left: $grid-unit-20;
    }
  }
  .languages {
    margin-right: 24px;
  }
}

.header-spacer {
  height: 64px;
  width: 100%;
}
