@import '@pushpress/shared-components/build/styles/config.scss';

.jumbo {
  @include jumbo;
}
.heading-1 {
  @include heading-1;
}
.heading-2 {
  @include heading-2;
}
.heading-3 {
  @include heading-3;
}
.heading-4 {
  @include heading-4;
}
.heading-5 {
  @include heading-5;
}
.body-lg {
  @include body-lg;
}
.body-md {
  @include body-md;
}
.body-sm {
  @include body-sm;
}

.black {
  color: $black;
}
.wolf-900 {
  color: $wolf-900;
}
.wolf-800 {
  color: $wolf-800;
}
.wolf-700 {
  color: $wolf-700;
}
.wolf-600 {
  color: $wolf-600;
}
.wolf-500 {
  color: $wolf-500;
}
.wolf-400 {
  color: $wolf-400;
}
.wolf-300 {
  color: $wolf-300;
}
.wolf-200 {
  color: $wolf-200;
}
.wolf-100 {
  color: $wolf-100;
}
.white {
  color: $white;
}

.italic {
  font-style: italic;
}
