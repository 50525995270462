@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title {
  @include heading-4;

  &.dark {
    color: $white;
  }
  &.light {
    color: $black;
  }
}
