.flag {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url(flags.png) no-repeat;
}
.flag.flag-ad {
  background-position: 0 0;
}
.flag.flag-ae {
  background-position: -32px 0;
}
.flag.flag-af {
  background-position: -64px 0;
}
.flag.flag-ag {
  background-position: -96px 0;
}
.flag.flag-ai {
  background-position: -128px 0;
}
.flag.flag-al {
  background-position: -160px 0;
}
.flag.flag-am {
  background-position: -192px 0;
}
.flag.flag-an {
  background-position: -224px 0;
}
.flag.flag-ao {
  background-position: -256px 0;
}
.flag.flag-aq {
  background-position: -288px 0;
}
.flag.flag-ar {
  background-position: -320px 0;
}
.flag.flag-as {
  background-position: -352px 0;
}
.flag.flag-at {
  background-position: -384px 0;
}
.flag.flag-au {
  background-position: -416px 0;
}
.flag.flag-aw {
  background-position: -448px 0;
}
.flag.flag-ax {
  background-position: -480px 0;
}
.flag.flag-az {
  background-position: 0 -32px;
}
.flag.flag-ba {
  background-position: -32px -32px;
}
.flag.flag-bb {
  background-position: -64px -32px;
}
.flag.flag-bd {
  background-position: -96px -32px;
}
.flag.flag-be {
  background-position: -128px -32px;
}
.flag.flag-bf {
  background-position: -160px -32px;
}
.flag.flag-bg {
  background-position: -192px -32px;
}
.flag.flag-bh {
  background-position: -224px -32px;
}
.flag.flag-bi {
  background-position: -256px -32px;
}
.flag.flag-bj {
  background-position: -288px -32px;
}
.flag.flag-bl {
  background-position: -320px -32px;
}
.flag.flag-bm {
  background-position: -352px -32px;
}
.flag.flag-bn {
  background-position: -384px -32px;
}
.flag.flag-bo {
  background-position: -416px -32px;
}
.flag.flag-br {
  background-position: -448px -32px;
}
.flag.flag-bs {
  background-position: -480px -32px;
}
.flag.flag-bt {
  background-position: 0 -64px;
}
.flag.flag-bw {
  background-position: -32px -64px;
}
.flag.flag-by {
  background-position: -64px -64px;
}
.flag.flag-bz {
  background-position: -96px -64px;
}
.flag.flag-ca {
  background-position: -128px -64px;
}
.flag.flag-cc {
  background-position: -160px -64px;
}
.flag.flag-cd {
  background-position: -192px -64px;
}
.flag.flag-cf {
  background-position: -224px -64px;
}
.flag.flag-cg {
  background-position: -256px -64px;
}
.flag.flag-ch {
  background-position: -288px -64px;
}
.flag.flag-ci {
  background-position: -320px -64px;
}
.flag.flag-ck {
  background-position: -352px -64px;
}
.flag.flag-cl {
  background-position: -384px -64px;
}
.flag.flag-cm {
  background-position: -416px -64px;
}
.flag.flag-cn {
  background-position: -448px -64px;
}
.flag.flag-co {
  background-position: -480px -64px;
}
.flag.flag-cr {
  background-position: 0 -96px;
}
.flag.flag-cu {
  background-position: -32px -96px;
}
.flag.flag-cv {
  background-position: -64px -96px;
}
.flag.flag-cw {
  background-position: -96px -96px;
}
.flag.flag-cx {
  background-position: -128px -96px;
}
.flag.flag-cy {
  background-position: -160px -96px;
}
.flag.flag-cz {
  background-position: -192px -96px;
}
.flag.flag-de {
  background-position: -224px -96px;
}
.flag.flag-dj {
  background-position: -256px -96px;
}
.flag.flag-dk {
  background-position: -288px -96px;
}
.flag.flag-dm {
  background-position: -320px -96px;
}
.flag.flag-do {
  background-position: -352px -96px;
}
.flag.flag-dz {
  background-position: -384px -96px;
}
.flag.flag-ec {
  background-position: -416px -96px;
}
.flag.flag-ee {
  background-position: -448px -96px;
}
.flag.flag-eg {
  background-position: -480px -96px;
}
.flag.flag-eh {
  background-position: 0 -128px;
}
.flag.flag-er {
  background-position: -32px -128px;
}
.flag.flag-es {
  background-position: -64px -128px;
}
.flag.flag-et {
  background-position: -96px -128px;
}
.flag.flag-eu {
  background-position: -128px -128px;
}
.flag.flag-fi {
  background-position: -160px -128px;
}
.flag.flag-fj {
  background-position: -192px -128px;
}
.flag.flag-fk {
  background-position: -224px -128px;
}
.flag.flag-fm {
  background-position: -256px -128px;
}
.flag.flag-fo {
  background-position: -288px -128px;
}
.flag.flag-fr {
  background-position: -320px -128px;
}
.flag.flag-ga {
  background-position: -352px -128px;
}
.flag.flag-gb {
  background-position: -384px -128px;
}
.flag.flag-gd {
  background-position: -416px -128px;
}
.flag.flag-ge {
  background-position: -448px -128px;
}
.flag.flag-gg {
  background-position: -480px -128px;
}
.flag.flag-gh {
  background-position: 0 -160px;
}
.flag.flag-gi {
  background-position: -32px -160px;
}
.flag.flag-gl {
  background-position: -64px -160px;
}
.flag.flag-gm {
  background-position: -96px -160px;
}
.flag.flag-gn {
  background-position: -128px -160px;
}
.flag.flag-gq {
  background-position: -160px -160px;
}
.flag.flag-gr {
  background-position: -192px -160px;
}
.flag.flag-gs {
  background-position: -224px -160px;
}
.flag.flag-gt {
  background-position: -256px -160px;
}
.flag.flag-gu {
  background-position: -288px -160px;
}
.flag.flag-gw {
  background-position: -320px -160px;
}
.flag.flag-gy {
  background-position: -352px -160px;
}
.flag.flag-hk {
  background-position: -384px -160px;
}
.flag.flag-hn {
  background-position: -416px -160px;
}
.flag.flag-hr {
  background-position: -448px -160px;
}
.flag.flag-ht {
  background-position: -480px -160px;
}
.flag.flag-hu {
  background-position: 0 -192px;
}
.flag.flag-ic {
  background-position: -32px -192px;
}
.flag.flag-id {
  background-position: -64px -192px;
}
.flag.flag-ie {
  background-position: -96px -192px;
}
.flag.flag-il {
  background-position: -128px -192px;
}
.flag.flag-im {
  background-position: -160px -192px;
}
.flag.flag-in {
  background-position: -192px -192px;
}
.flag.flag-iq {
  background-position: -224px -192px;
}
.flag.flag-ir {
  background-position: -256px -192px;
}
.flag.flag-is {
  background-position: -288px -192px;
}
.flag.flag-it {
  background-position: -320px -192px;
}
.flag.flag-je {
  background-position: -352px -192px;
}
.flag.flag-jm {
  background-position: -384px -192px;
}
.flag.flag-jo {
  background-position: -416px -192px;
}
.flag.flag-jp {
  background-position: -448px -192px;
}
.flag.flag-ke {
  background-position: -480px -192px;
}
.flag.flag-kg {
  background-position: 0 -224px;
}
.flag.flag-kh {
  background-position: -32px -224px;
}
.flag.flag-ki {
  background-position: -64px -224px;
}
.flag.flag-km {
  background-position: -96px -224px;
}
.flag.flag-kn {
  background-position: -128px -224px;
}
.flag.flag-kp {
  background-position: -160px -224px;
}
.flag.flag-kr {
  background-position: -192px -224px;
}
.flag.flag-kw {
  background-position: -224px -224px;
}
.flag.flag-ky {
  background-position: -256px -224px;
}
.flag.flag-kz {
  background-position: -288px -224px;
}
.flag.flag-la {
  background-position: -320px -224px;
}
.flag.flag-lb {
  background-position: -352px -224px;
}
.flag.flag-lc {
  background-position: -384px -224px;
}
.flag.flag-li {
  background-position: -416px -224px;
}
.flag.flag-lk {
  background-position: -448px -224px;
}
.flag.flag-lr {
  background-position: -480px -224px;
}
.flag.flag-ls {
  background-position: 0 -256px;
}
.flag.flag-lt {
  background-position: -32px -256px;
}
.flag.flag-lu {
  background-position: -64px -256px;
}
.flag.flag-lv {
  background-position: -96px -256px;
}
.flag.flag-ly {
  background-position: -128px -256px;
}
.flag.flag-ma {
  background-position: -160px -256px;
}
.flag.flag-mc {
  background-position: -192px -256px;
}
.flag.flag-md {
  background-position: -224px -256px;
}
.flag.flag-me {
  background-position: -256px -256px;
}
.flag.flag-mf {
  background-position: -288px -256px;
}
.flag.flag-mg {
  background-position: -320px -256px;
}
.flag.flag-mh {
  background-position: -352px -256px;
}
.flag.flag-mk {
  background-position: -384px -256px;
}
.flag.flag-ml {
  background-position: -416px -256px;
}
.flag.flag-mm {
  background-position: -448px -256px;
}
.flag.flag-mn {
  background-position: -480px -256px;
}
.flag.flag-mo {
  background-position: 0 -288px;
}
.flag.flag-mp {
  background-position: -32px -288px;
}
.flag.flag-mq {
  background-position: -64px -288px;
}
.flag.flag-mr {
  background-position: -96px -288px;
}
.flag.flag-ms {
  background-position: -128px -288px;
}
.flag.flag-mt {
  background-position: -160px -288px;
}
.flag.flag-mu {
  background-position: -192px -288px;
}
.flag.flag-mv {
  background-position: -224px -288px;
}
.flag.flag-mw {
  background-position: -256px -288px;
}
.flag.flag-mx {
  background-position: -288px -288px;
}
.flag.flag-my {
  background-position: -320px -288px;
}
.flag.flag-mz {
  background-position: -352px -288px;
}
.flag.flag-na {
  background-position: -384px -288px;
}
.flag.flag-nc {
  background-position: -416px -288px;
}
.flag.flag-ne {
  background-position: -448px -288px;
}
.flag.flag-nf {
  background-position: -480px -288px;
}
.flag.flag-ng {
  background-position: 0 -320px;
}
.flag.flag-ni {
  background-position: -32px -320px;
}
.flag.flag-nl {
  background-position: -64px -320px;
}
.flag.flag-no {
  background-position: -96px -320px;
}
.flag.flag-np {
  background-position: -128px -320px;
}
.flag.flag-nr {
  background-position: -160px -320px;
}
.flag.flag-nu {
  background-position: -192px -320px;
}
.flag.flag-nz {
  background-position: -224px -320px;
}
.flag.flag-om {
  background-position: -256px -320px;
}
.flag.flag-pa {
  background-position: -288px -320px;
}
.flag.flag-pe {
  background-position: -320px -320px;
}
.flag.flag-pf {
  background-position: -352px -320px;
}
.flag.flag-pg {
  background-position: -384px -320px;
}
.flag.flag-ph {
  background-position: -416px -320px;
}
.flag.flag-pk {
  background-position: -448px -320px;
}
.flag.flag-pl {
  background-position: -480px -320px;
}
.flag.flag-pn {
  background-position: 0 -352px;
}
.flag.flag-pr {
  background-position: -32px -352px;
}
.flag.flag-ps {
  background-position: -64px -352px;
}
.flag.flag-pt {
  background-position: -96px -352px;
}
.flag.flag-pw {
  background-position: -128px -352px;
}
.flag.flag-py {
  background-position: -160px -352px;
}
.flag.flag-qa {
  background-position: -192px -352px;
}
.flag.flag-ro {
  background-position: -224px -352px;
}
.flag.flag-rs {
  background-position: -256px -352px;
}
.flag.flag-ru {
  background-position: -288px -352px;
}
.flag.flag-rw {
  background-position: -320px -352px;
}
.flag.flag-sa {
  background-position: -352px -352px;
}
.flag.flag-sb {
  background-position: -384px -352px;
}
.flag.flag-sc {
  background-position: -416px -352px;
}
.flag.flag-sd {
  background-position: -448px -352px;
}
.flag.flag-se {
  background-position: -480px -352px;
}
.flag.flag-sg {
  background-position: 0 -384px;
}
.flag.flag-sh {
  background-position: -32px -384px;
}
.flag.flag-si {
  background-position: -64px -384px;
}
.flag.flag-sk {
  background-position: -96px -384px;
}
.flag.flag-sl {
  background-position: -128px -384px;
}
.flag.flag-sm {
  background-position: -160px -384px;
}
.flag.flag-sn {
  background-position: -192px -384px;
}
.flag.flag-so {
  background-position: -224px -384px;
}
.flag.flag-sr {
  background-position: -256px -384px;
}
.flag.flag-ss {
  background-position: -288px -384px;
}
.flag.flag-st {
  background-position: -320px -384px;
}
.flag.flag-sv {
  background-position: -352px -384px;
}
.flag.flag-sy {
  background-position: -384px -384px;
}
.flag.flag-sz {
  background-position: -416px -384px;
}
.flag.flag-tc {
  background-position: -448px -384px;
}
.flag.flag-td {
  background-position: -480px -384px;
}
.flag.flag-tf {
  background-position: 0 -416px;
}
.flag.flag-tg {
  background-position: -32px -416px;
}
.flag.flag-th {
  background-position: -64px -416px;
}
.flag.flag-tj {
  background-position: -96px -416px;
}
.flag.flag-tk {
  background-position: -128px -416px;
}
.flag.flag-tl {
  background-position: -160px -416px;
}
.flag.flag-tm {
  background-position: -192px -416px;
}
.flag.flag-tn {
  background-position: -224px -416px;
}
.flag.flag-to {
  background-position: -256px -416px;
}
.flag.flag-tr {
  background-position: -288px -416px;
}
.flag.flag-tt {
  background-position: -320px -416px;
}
.flag.flag-tv {
  background-position: -352px -416px;
}
.flag.flag-tw {
  background-position: -384px -416px;
}
.flag.flag-tz {
  background-position: -416px -416px;
}
.flag.flag-ua {
  background-position: -448px -416px;
}
.flag.flag-ug {
  background-position: -480px -416px;
}
.flag.flag-us {
  background-position: 0 -448px;
}
.flag.flag-uy {
  background-position: -32px -448px;
}
.flag.flag-uz {
  background-position: -64px -448px;
}
.flag.flag-va {
  background-position: -96px -448px;
}
.flag.flag-vc {
  background-position: -128px -448px;
}
.flag.flag-ve {
  background-position: -160px -448px;
}
.flag.flag-vg {
  background-position: -192px -448px;
}
.flag.flag-vi {
  background-position: -224px -448px;
}
.flag.flag-vn {
  background-position: -256px -448px;
}
.flag.flag-vu {
  background-position: -288px -448px;
}
.flag.flag-wf {
  background-position: -320px -448px;
}
.flag.flag-ws {
  background-position: -352px -448px;
}
.flag.flag-ye {
  background-position: -384px -448px;
}
.flag.flag-yt {
  background-position: -416px -448px;
}
.flag.flag-za {
  background-position: -448px -448px;
}
.flag.flag-zm {
  background-position: -480px -448px;
}
.flag.flag-zw {
  background-position: 0 -480px;
}
